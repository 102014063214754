import React, {Component} from "react";
import {connect} from "react-redux";
import {Route, BrowserRouter, Redirect, Switch, Link} from "react-router-dom";
import {analytics, AnalyticsTracker, BHGToast} from '@bytehogs/design-system';
import {ToastProvider} from 'react-toast-notifications';
import Login from "../../pages/auth/Login";
import Logout from "../../pages/auth/Logout";
import {bindActionCreators} from "redux";
import {AuthActionCreators} from "../../redux/actions/auth";
import {Dashboard} from "../../pages/Dashboard";
import TableView from "../../pages/TableView";

const ProtectedRoute = ({ isVerified, li, ...props }) => {
    return isVerified ? (
        <Route {...props} />
    ) : (
        <Redirect to={li ? "/app" : "/login?return_url=" + props.path} />
    );
};

class Routing extends Component {
    constructor(props) {
        super(props);
        //analytics.initialize('', {});

        this.state = { canRender: false };
    }

    componentWillMount() {
        const { authActions } = this.props;
        authActions.checkToken().finally(() => this.setState({ canRender: true }));
    }

    render() {
        const { canRender } = this.state;
        const { isAuthed } = this.props;
        if (canRender) {
            return (
                <BrowserRouter>
                    <ToastProvider components={{ Toast: BHGToast }}>
                        {/*<Header />*/}
                        <Switch>
                            <ProtectedRoute isVerified={!isAuthed} li exact path="/login" component={Login}/>
                            <Route exact path="/logout" component={Logout}/>
                            <ProtectedRoute isVerified={isAuthed} exact path="/app" component={Dashboard}/>
                            <ProtectedRoute isVerified={isAuthed} exact path="/app/pt/:tableId" component={TableView}/>
                            {/*<ProtectedRoute
                                isVerified={!isAuthed}
                                li
                                exact
                                path="/register"
                                component={AnalyticsTracker(Register)}
                            />*/}

                            <Redirect to="/login" />
                        </Switch>
                        {/*<Footer />*/}
                    </ToastProvider>
                </BrowserRouter>
            );
        }
        return <div className="container">Loading...</div>;
    }
}


const mapStateToProps = (state) => ({
    isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
