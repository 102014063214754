import {ProjectsActionTypes} from "../../types";

export const initialState = {
    loading: false,
    projects: [],
    projectErr: null,
    tables: {}
};

export const ProjectsReducer = (state = initialState, action) => {
    const { type, payload, error } = action;
    switch (type) {
        case ProjectsActionTypes.GET_PROJECTS_REQ: {
            return {
                ...state,
                loading: true
            }
        }
        case ProjectsActionTypes.GET_PROJECTS_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    projectErr: error
                }
            }
            return {
                ...state,
                loading: false,
                projectErr: null,
                projects: payload.projects
            }
        }
        case ProjectsActionTypes.GET_DATA_REQ: {
            return {
                ...state,
                loading: true
            }
        }
        case ProjectsActionTypes.GET_DATA_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    projectErr: error
                }
            }
            return {
                ...state,
                loading: false,
                projectErr: null,
                tables: {...state.tables, [payload.tableId]: payload.data}
            }
        }
        case "LOGOUT": {
            return initialState;
        }
        default:
            return state;
    }
};
