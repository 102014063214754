import React from "react";
import Sidebar from "./Sidebar";

export const PageWrapper = props => {
    const {className, children} = props;
    return <div className={"page-wrapper view " + className}>
        <Sidebar/>
        <div className="view-content">
            {children}
        </div>
    </div>
}
