import React, {useEffect, useState} from "react";
import {BHGCard} from "@bytehogs/design-system";
import {PageWrapper} from "../components/global/PageWrapper";
import {bindActionCreators} from "redux";
import {ProjectsActionCreators} from "../redux/actions/projects";
import {connect} from "react-redux";
import {DynamicTable} from "../components/DynamicTable";

const TableView = props => {
    const [title, setTitle] = useState("");
    const {match: {params: {tableId}}, projectActions, tables} = props;

    useEffect( () => {
        async function fetchData() {
            await projectActions.getData(tableId);
        }
        fetchData();
    }, [tableId])

    useEffect( () => {
        props.projects.forEach(project => {
            let table = project.tables.find(t => t.tableId == tableId);
            if(table) setTitle(project.name + " - " + table.displayName);
        });
    }, [props])



    return <PageWrapper className="table">
        <BHGCard>
            <h2>{title}</h2>
            <DynamicTable data={tables[tableId]} title={title} tableId={tableId}/>
        </BHGCard>
    </PageWrapper>
}

const mapStateToProps = (state) => ({
    projectErr: state.ProjectsReducer.projectErr,
    projects: state.ProjectsReducer.projects,
    tables: state.ProjectsReducer.tables,
});

const mapDispatchToProps = (dispatch) => ({
    projectActions: bindActionCreators(ProjectsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TableView);
