import React from "react";
import "./assets/sass/index.scss";
import Routing from "./components/global/Routing";
import {Provider} from "react-redux";
import store from "./redux";

function App() {
  return <div className="app light">
    <Provider store={store}>
      <Routing/>
    </Provider>
  </div>;
}

export default App;
