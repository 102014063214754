import React, {useEffect, useState} from "react";
import {BHGCard, BHGInputWrapper, BHGInput, BHGButton} from "@bytehogs/design-system";
import {Logo} from "../../components/global/Logo";
import {AuthActionCreators} from "../../redux/actions/auth";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";

const Login = props => {
    console.log(props);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!loading) {
            setLoading(true);
            const validationErrors = handleValidation();
            if (validationErrors > 0) setLoading(false)
            else {
                const { authActions } = props;
                try {
                    await authActions.login({ email, password });
                } catch (e) {
                    setLoading(false);
                    setErrors({...errors, form: e.message || "Sorry, something went wrong"});
                }
            }
        }
    };

    const handleValidation = (onlyExistingErrors = false) => {
        if (
            (onlyExistingErrors && Object.keys(errors).length > 0) ||
            !onlyExistingErrors
        ) {
            let errors = {};
            setErrors(errors)
            if (!email) errors.email = "Required";
            else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i.test(email))
                errors.email = "Invalid email";
            if (!password || password.length < 6) errors.password = "Required";
            if (Object.keys(errors).length > 0) setErrors(errors);
            return Object.keys(errors).length;
        }
    };

    useEffect(() => {
        if(props.authErr) {
            setLoading(false);
            setErrors({...errors, form: props.authErr});
        }
    }, [props.authErr])

    useEffect(() => {
        if(props.isAuthed) {
            setLoading(false);
            props.history.push('/app');
        }
    }, [props.isAuthed])

    return <div className="view auth login">
        <div className="container">
            <BHGCard>
                <Logo/>
                <div className="login-form">
                    <form>
                        <BHGInputWrapper error={errors.email}>
                            <BHGInput className="form-control admin-input" placeholder="Email address" value={email} onChange={e => setEmail(e.target.value)} error={errors.email}/>
                        </BHGInputWrapper>
                        <BHGInputWrapper error={errors.password}>
                            <BHGInput className="form-control admin-input" type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} error={errors.password}/>
                        </BHGInputWrapper>
                        {errors.form && <div>{errors.form}</div>}
                        <BHGButton onClick={handleSubmit} loading={loading}>Login</BHGButton>
                    </form>
                </div>
            </BHGCard>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    authErr: state.AuthReducer.authErr,
    isAuthed: state.AuthReducer.isAuthed,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
