import React, {useEffect, useState} from "react";
import {BHGButton} from "@bytehogs/design-system";
import {Logo} from "./Logo";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {ProjectsActionCreators} from "../../redux/actions/projects";
import {Link} from "react-router-dom";

const Sidebar = props => {
    const [loading, setLoading] = useState(true);
    const {projectActions, projects} = props;

    useEffect( () => {
        async function fetchData() {
            await projectActions.getProjects();
            setLoading(false)
        }
        fetchData();
    }, []);

    return <div className="sidebar">
        <Logo/>
        <div className="projects-list">
            {loading && <div>Loading...</div>}
            {projects.map(project => {
                return <div>
                    <h4>{project.name}</h4>
                    <hr/>
                    <div>
                        {project.tables.map(table => {
                            return <div key={table.tableId}><Link to={"/app/pt/"+table.tableId}>{table.displayName}</Link></div>
                        })}
                    </div>
                    <hr/>
                </div>
            })}
        </div>
        <div className="actions">
            <Link to="/logout"><BHGButton>Logout</BHGButton></Link>
        </div>
    </div>
}

const mapStateToProps = (state) => ({
    projectErr: state.ProjectsReducer.projectErr,
    projects: state.ProjectsReducer.projects,
});

const mapDispatchToProps = (dispatch) => ({
    projectActions: bindActionCreators(ProjectsActionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
