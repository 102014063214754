import { AuthActionTypes } from "../../types";

export const initialState = {
    loading: false,
    isAuthed: false,
    authErr: null,
};

export const AuthReducer = (state = initialState, action) => {
    const { type, error } = action;
    switch (type) {
        case AuthActionTypes.AUTH_STATUS: {
            if (error) {
                return {
                    ...state,
                    isAuthed: false,
                };
            }
            return {
                ...state,
                isAuthed: true,
            };
        }
        case AuthActionTypes.LOGIN_REQ: {
            return {
                ...state,
                loading: true
            }
        }
        case AuthActionTypes.LOGIN_RES: {
            if (error) {
                return {
                    ...state,
                    loading: false,
                    authErr: error
                }
            }
            return {
                ...state,
                loading: false,
                authErr: null,
                isAuthed: true
            }
        }
        case "LOGOUT": {
            return initialState;
        }
        default:
            return state;
    }
};
