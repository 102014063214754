export const AuthActionTypes = {
    AUTH_STATUS: "AUTH_STATUS",
    LOGIN_REQ: "LOGIN_REQ",
    LOGIN_RES: "LOGIN_RES",
}

export const ProjectsActionTypes = {
    GET_PROJECTS_REQ: "GET_PROJECTS_REQ",
    GET_PROJECTS_RES: "GET_PROJECTS_RES",
    GET_DATA_REQ: "GET_DATA_REQ",
    GET_DATA_RES: "GET_DATA_RES",
}
