import {combineReducers} from 'redux';
import { reducer as formReducer } from 'redux-form';
import {AuthReducer} from "./auth";
import {ProjectsReducer} from "./projects";

const reducers = combineReducers({
    form: formReducer,
    AuthReducer,
    ProjectsReducer
});

export default reducers;
