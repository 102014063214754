import React, {useEffect, useState} from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { selectFilter } from 'react-bootstrap-table2-filter';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import cellEditFactory, {Type} from 'react-bootstrap-table2-editor';
import {BytehogsAPI} from "@bytehogs/design-system";
const { ExportCSVButton } = CSVExport;
const { SearchBar } = Search;
const nonEditColumns = ['id', 'updatedAt', 'createdAt'];
const textAreaColumns = ['description', 'metadata', 'requirements'];

export const DynamicTable = props => {
    const {data, title, tableId} = props;
    const [loading, setLoading] = useState(false)
    const [pageTitle, setTitle] = useState(null)
    const [mapKeys, setMapKeys] = useState([]);

    useEffect(() => {
        dataLoader(props);
    }, [props, title]);

    const dataLoader = (pps) => {
        if(pps.data && (pageTitle !== pps.title) && !loading) {
            setLoading(true);
            setTitle(pps.title);
            setMapKeys([]);
            let keys = [];
            let keySet = new Set();
            pps.data.forEach((row, index) => {
                Object.keys(row).forEach(key =>{

                    if(!keySet.has(key)) {
                        keySet.add(key);
                        keys.push({
                            dataField: key,
                            text: key,
                            editable: () => !nonEditColumns.includes(key),
                            editor: {
                                type: textAreaColumns.includes(key) ? Type.TEXTAREA : Type.TEXT
                            }
                        })
                    }
                });
            });
            setMapKeys(keys);
            setLoading(false)
        }
    }

    const handleEdit = async (oldValue, newValue, row, column) => {
        let newValues = {};
        let oldValues = {};
        newValues.id = row.id;
        newValues[column.dataField] = isNaN(Number(newValue)) ? newValue : Number(newValue);
        oldValues[column.dataField] = isNaN(Number(oldValue)) ? oldValue : Number(oldValue);
        let sessionToken = await localStorage.getItem("sToken");
        let payload = {
            sessionToken,
            tableId,
            operation: "updateData",
            data: newValues,
            oldValues
        }
        const response = await BytehogsAPI('bhg-a-be', "tables", payload, 'dev-api', null, true);
        console.log(response.data)
    }

    return <div>
        {loading && <div>Loading...</div>}
        {!loading && mapKeys.length > 0 &&  <ToolkitProvider
            keyField="id"
            data={data ? data : [{id: 1}]}
            columns={ mapKeys ? mapKeys : [{dataField: 'id', text: 'id'}] }
            search
            exportCSV={ {
                fileName: pageTitle.split(' ').join('')+'.csv',
                separator: ',',
                ignoreHeader: false,
                noAutoBOM: false,
                exportAll: true
            } }>
            {
                props => (
                    <div>
                        <SearchBar { ...props.searchProps } />
                        <hr />
                        <ExportCSVButton  { ...props.csvProps }>Export</ExportCSVButton>
                        <BootstrapTable
                            { ...props.baseProps }
                            pagination={paginationFactory({sizePerPage: 25})}
                            filter={ filterFactory() }
                            cellEdit={ cellEditFactory({ mode: 'dbclick', afterSaveCell: handleEdit })}
                            noDataIndication={ false ? <div>Loading...</div> : <div>No Data</div> }/>
                    </div>
                )
            }
        </ToolkitProvider>}

    </div>
}
