import { ProjectsActionTypes } from "../../types";
import {BytehogsAPI} from "@bytehogs/design-system";

const SERVICE = "bhg-a-be";

export const ProjectsActionCreators = {
  getProjects: () => async (dispatch) => {
    dispatch({
      type: ProjectsActionTypes.GET_PROJECTS_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = {operation: "getProjects", sessionToken};
      const response = await BytehogsAPI(SERVICE, "projects", payload, 'dev-api', null, true);
      dispatch({
        type: ProjectsActionTypes.GET_PROJECTS_RES,
        error: null,
        payload: response.data
      });
    } catch (e) {
      dispatch({
        type: ProjectsActionTypes.GET_PROJECTS_RES,
        error: e.data.message || e.message || "Something went wrong",
      });
    }
  },
  getData: (tableId, offset = 0) => async (dispatch) => {
    dispatch({
      type: ProjectsActionTypes.GET_DATA_REQ,
    });
    try {
      const sessionToken = await localStorage.getItem("sToken");
      let payload = {operation: "getData", sessionToken, tableId, offset};
      const response = await BytehogsAPI(SERVICE, "tables", payload, 'dev-api', null, true);
      dispatch({
        type: ProjectsActionTypes.GET_DATA_RES,
        error: null,
        payload: {tableId, data: response.data.data}
      });
    } catch (e) {
      dispatch({
        type: ProjectsActionTypes.GET_DATA_RES,
        error: e.data.message || e.message || "Something went wrong",
      });
    }
  },
};
